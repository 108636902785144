<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<div class="row mt-4">
			<div class="col-xs-12 col-lg-8 d-flex">
				<div class="h2 text-muted py-3">
					{{ translate('confirmation') }}
				</div>
			</div>
		</div>
		<div class="row mb-5">
			<div class="col-12 col-md-6">
				<ul class="list-group w-100 h-100">
					<li class="list-group-item h-auto">
						<div class="row">
							<div class="col">
								<div class="h5">
									{{ translate('product_step') }}
								</div>
							</div>
							<div class="col-auto text-center">
								<b-button
									variant="outline-primary"
									class="btn-sm my-auto ml-auto"
									:to="{ name: Object.keys(currentSteps).find(item => currentSteps[item].validation === 'product') }">
									{{ translate('edit') }}
								</b-button>
							</div>
						</div>
						<div class="row mt-3">
							<div
								v-if="!loadingProducts && products && packageName.length > 0"
								class="col-12">
								<b>
									{{ translate(packageName) }}
								</b>
								<!-- <div
									v-for="(item, key) in products"
									:key="key">
									<div
										v-if="typeof productsQty[item.attributes.sku] !== 'undefined' && productsQty[item.attributes.sku] > 0"
										class="row">
										<div class="col-5">
											{{ translate(item.attributes.code_name) }}
										</div>
										<div class="col-auto">
											{{ productsQty[item.attributes.sku] }}
										</div>
									</div>
								</div> -->
							</div>
							<div
								v-else-if="!loadingProducts && packageName.length === 0"
								class="col-12">
								<div>
									<b>
										{{ translate('membership') }}
									</b>
								</div>
							</div>
							<div
								v-else
								class="col-12 fade-in text-center d-flex justify-content-center align-items-center">
								<div class="col-12">
									<div class="h2">
										<i class="fa fa-fw fa-spinner fa-pulse" />
										<h4 class="mt-3">
											{{ translate('loading') }}
										</h4>
									</div>
								</div>
							</div>
						</div>
					</li>
					<li class="list-group-item h-auto">
						<div class="row">
							<div class="col">
								<div class="h5">
									{{ translate('account_step') }}
								</div>
							</div>
							<div class="col-auto text-center">
								<b-button
									variant="outline-primary"
									class="btn-sm my-auto ml-auto"
									:to="{ name: Object.keys(steps).find(item => steps[item].validation === 'shipping') }">
									{{ translate('edit') }}
								</b-button>
							</div>
						</div>
						<div class="row mt-3">
							<div class="col-auto">
								<p class="mb-0">
									{{ translate(form.gender) }}
								</p>
								<p class="mb-0">
									<i class="fas fa-birthday-cake mr-2" /> {{ $moment(form.birthdate).format(MMMDY_FORMAT) }}
								</p>
								<p
									v-if="!isCompany && requiredNinCountries.includes(country)"
									class="mb-0">
									<i class="fas fa-university mr-2" /> {{ form.national_identification_number }}
								</p>
								<div v-if="isCompany">
									<p class="mb-0">
										<i class="fas fa-university mr-2" /> {{ form.tax_id }}
									</p>
									<p class="mb-0">
										<i class="far fa-building mr-2" /> {{ form.company }}
									</p>
								</div>
							</div>
						</div>
					</li>
					<li class="list-group-item h-auto">
						<div class="row">
							<div class="col">
								<div class="h5">
									{{ translate('shipping_step') }}
								</div>
							</div>
							<div class="col-auto text-center">
								<b-button
									variant="outline-primary"
									class="btn-sm my-auto ml-auto"
									:to="{ name: Object.keys(steps).find(item => steps[item].validation === 'shipping') }">
									{{ translate('edit') }}
								</b-button>
							</div>
						</div>
						<div class="row mt-3">
							<div
								v-if="!loadingShippingAddress && shipping.country_code !== ''"
								class="col-auto">
								<p class="mb-0">
									<b>{{ shipping.first_name }} {{ shipping.last_name }}</b>
								</p>
								<p class="mb-0">
									{{ shipping.address }}
								</p>
								<p class="mb-0">
									{{ shipping.address2 }}
								</p>
								<p class="mb-0">
									{{ shipping.city }}, {{ shipping.region_id }}, {{ shipping.postcode }}
								</p>
								<p class="mb-0">
									{{ translate(shipping.country_code.toLowerCase()) }}
								</p>
								<!-- <p
									v-if="countriesListForSignature.includes(country)"
									class="mb-0">
									{{ translate('signature_label') }}: {{ translate(shipping.signature_required.toString()) }}
								</p> -->
								<div
									v-if="Object.keys(shippingMethodInfo).length"
									class="mt-1 mb-0">
									<img
										v-if="shippingMethodInfo.image"
										:src="require(`@/assets/images/common/shipping/carriers/${shippingMethodInfo.image}`)"
										class="my-auto border"
										style="width: 45px; height: 30px;">
									<p class="text-muted d-inline">
										<span
											v-if="shippingMethodInfo.name"
											class="ml-1">
											{{ translate(shippingMethodInfo.name) }}
										</span>
										<small
											v-if="deliveryDays.length"
											class="font-italic">
											<br>{{ translate('estimated_date') }}{{ translate(deliveryDays) }}
										</small>
									</p>
									<br>
									<!-- <small
										v-if="shippingNotice.length"
										class="font-italic"
										v-html="translate(shippingNotice)" /> -->
								</div>
							</div>
							<div
								v-else
								class="col-12 fade-in text-center d-flex justify-content-center align-items-center">
								<div class="col-12">
									<div class="h2">
										<i class="fa fa-fw fa-spinner fa-pulse" />
										<h4 class="mt-3">
											{{ translate('loading') }}
										</h4>
									</div>
								</div>
							</div>
						</div>
					</li>
					<li class="list-group-item h-auto">
						<div class="row">
							<div class="col">
								<div class="h5 mb-0">
									{{ translate('payment_step') }}
								</div>
								<small><span v-if="walletSplit">{{ translate('cashondelivery') }} + </span> {{ translate(paymentMethod.name) }}</small>
							</div>
							<div class="col-auto text-center">
								<b-button
									variant="outline-primary"
									class="btn-sm my-auto ml-auto"
									:to="{ name: Object.keys(steps).find(item => steps[item].validation === 'payment') }">
									{{ translate('edit') }}
								</b-button>
							</div>
						</div>
						<template v-if="!loadingBillingAddress && billing.country_code !== ''">
							<b-row>
								<b-col>
									<p class="mb-0">
										<b>{{ billing.first_name }} {{ billing.last_name }}</b>
									</p>
									<p class="mb-0">
										{{ billing.address }}
									</p>
									<p class="mb-0">
										{{ billing.address2 }}
									</p>
									<p class="mb-0">
										{{ billing.city }}, {{ billing.region_id }}, {{ billing.postcode }}
									</p>
									<p class="mb-0">
										{{ translate(billing.country_code.toLowerCase()) }}
									</p>
								</b-col>
							</b-row>
							<b-row class="mt-2">
								<b-col
									:order="Number(walletSplit)"
									:class="{
										'col-auto': !walletSplit && !isSplitPayment && !['xs', 'sm'].includes(windowWidth),
										'col-12': ['xs', 'sm'].includes(windowWidth),
										'mt-2': ['xs', 'sm'].includes(windowWidth)
									}">
									<p class="mb-0">
										<b v-if="walletSplit">{{ translate(paymentMethod.name) }}</b>
										<b v-if="isSplitPayment">{{ translate('main_credit_card') }}</b>
									</p>
									<p
										v-if="isCreditCard"
										class="mb-0 d-inline">
										<img
											:src="require(`@/assets/images/common/cc/logos/${cardLogo}.png`)"
											class="my-auto mr-1"
											alt="Card Logo"
											style="width:45px; height:30px;">
										<span class="text-muted"> ****{{ paymentMethod.last_digits.length > 0 ? paymentMethod.last_digits : '' }}</span>
									</p>
									<p
										:class="{ 'ml-2': isCreditCard }"
										class="mb-0 d-inline">
										<b v-if="walletSplit">{{ numericTotal - walletSplitAmount | currency('usd', translate) }}</b>
										<b v-if="isSplitPayment">{{ displayedSplitAmount1 }}</b>
									</p>
								</b-col>
								<template>
									<b-col
										v-if="!isSplitPayment && canSplitWithWallet"
										:order="Number(!walletSplit)"
										:class="{ 'mt-2': ['xs', 'sm'].includes(windowWidth) }"
										:style="!walletSplit && !isCreditCard ? 'margin-top: -35px' : ''">
										<div
											:class="{ 'justify-content-end': !walletSplit, 'justify-content-start': walletSplit }"
											class="d-flex align-items-center h-100">
											<span
												v-if="!walletSplit"
												class="text-primary btn-link pointer"
												@click="$router.push({ name: Object.keys(steps).find(item => steps[item].validation === 'payment') })">
												{{ translate('split_between_two_payment_methods', { first: translate('cashondelivery'), second: translate('other_method') }) }}
											</span>
											<b v-else>
												{{ translate('cashondelivery') }}
												<br>
												{{ walletSplitAmount | currency('usd', translate) }}
											</b>
										</div>
									</b-col>
									<b-col
										v-if="isSplitPayment"
										:class="{
											'col-md-6': !['xs', 'sm'].includes(windowWidth),
											'mt-2': ['xs', 'sm'].includes(windowWidth)
										}"
										class="col-12 mb-2">
										<p class="mb-0">
											<b>{{ translate('additional_credit_card') }}</b>
										</p>
										<p class="mb-0 d-inline">
											<img
												:src="require(`@/assets/images/common/cc/logos/${cardLogo2}.png`)"
												class="my-auto mr-1"
												alt="Card Logo"
												style="width:45px; height:30px;">
											<span class="text-muted"> ****{{ paymentMethod.last_digits_2.length > 0 ? paymentMethod.last_digits_2 : '' }}</span>
										</p>
										<p
											class="mb-0 d-inline ml-2">
											<b>{{ displayedSplitAmount2 }}</b>
										</p>
									</b-col>
								</template>
							</b-row>
						</template>
						<div
							v-else
							class="row">
							<div
								class="col-12 fade-in text-center d-flex justify-content-center align-items-center">
								<div class="col-12">
									<div class="h2">
										<i class="fa fa-fw fa-spinner fa-pulse" />
										<h4 class="mt-3">
											{{ translate('loading') }}
										</h4>
									</div>
								</div>
							</div>
						</div>
					</li>
				</ul>
			</div>
			<div class="col-12 col-md-6 mt-3 mt-md-0">
				<div class="row h-100">
					<div class="col-12">
						<li class="list-group-item h-auto rounded">
							<div class="table-responsive my-0">
								<is-loading
									:loading-label="translate('loading')"
									:no-data-label="translate('data_not_found')"
									:loading="loadingCartTotals"
									:has-data="true"
									:class="loadingCartTotals ? 'mt-3' : ''" />
								<table
									v-if="!loadingCartTotals && hasCartTotals"
									class="table table-borderless m-0">
									<thead>
										<tr class="h5 bg-white text-gray-dark">
											<th colspan="4">
												{{ translate('product_details') }}
											</th>
										</tr>
									</thead>
									<thead>
										<tr class="text-primary text-center border-bottom bg-white">
											<th class="text-left">
												{{ translate('product') }}
											</th>
											<th>
												{{ translate('qty') }}
											</th>
											<th v-if="!isPack">
												{{ translate('bv') }}
											</th>
											<th class="text-right">
												{{ translate('subtotal') }}
											</th>
										</tr>
									</thead>
									<tbody>
										<template v-for="(children, parentCodeName) in configurableProducts">
											<tr :key="parentCodeName">
												<td class="text-left text-wrap font-weight-bolder pb-0">
													{{ translate(parentCodeName) }}
												</td>
											</tr>
											<tr
												v-for="(child, index) in children"
												:key="child.sku">
												<td
													:class="index === children.length - 1 ? 'pb-2' : 'pb-1'"
													class="text-left font-weight-bold text-wrap pl-4">
													{{ translate(child.code_name) }}
													<a
														v-if="productHasLink(child.sku)"
														:href="productsLinks[child.sku]"
														target="_blank"
														class="text-decoration-none link-primary">
														<i class="fas fa-info-circle" />
													</a>
												</td>
												<td
													:class="index === children.length - 1 ? 'pb-2' : 'pb-1'"
													class="text-center">
													{{ child.qty }}
												</td>
												<td
													v-if="!isPack"
													:class="index === children.length - 1 ? 'pb-2' : 'pb-1'"
													class="text-center">
													{{ child.total_volume }}
												</td>
												<td
													:class="index === children.length - 1 ? 'pb-2' : 'pb-1'"
													class="text-right">
													{{ child.sub_total }}
												</td>
											</tr>
										</template>
										<tr
											v-for="item in simpleProducts"
											:key="item.item_id">
											<td class="text-left text-wrap font-weight-bolder pb-1">
												{{ translate(item.code_name) }}
												<a
													v-if="productHasLink(item.sku)"
													:href="productsLinks[item.sku]"
													target="_blank"
													class="text-decoration-none link-primary">
													<i class="fas fa-info-circle" />
												</a>
											</td>
											<td class="text-center pb-1">
												{{ item.qty }}
											</td>
											<td
												v-if="!isPack"
												class="text-center pb-1">
												{{ item.total_volume }}
											</td>
											<td class="text-right pb-1">
												{{ item.sub_total }}
											</td>
										</tr>
										<tr
											v-for="(item, index) in cartTotals.totals"
											:key="index">
											<td
												:colspan="isPack ? 2 : 3"
												:class="{
													'font-weight-bolder': !smallTextTotals.includes(index),
												}"
												class="pb-0 pt-1 text-right">
												{{ index == 'shipping' ? translate('shipping_and_handling') : translate(index) }}
											</td>
											<td
												v-if="item === '$0'"
												class="pt-1 text-right success-alt">
												{{ translate('free_item') }}
											</td>
											<td
												v-else
												class="pb-0 pt-1 text-right">
												{{ item }}
											</td>
										</tr>
										<tr>
											<td
												:colspan="isPack ? 2 : 3"
												class="pb-1 pb-3 font-weight-bolder text-right text-primary h5">
												{{ translate('total') }}
											</td>
											<td class="pb-1 pb-3 text-right text-primary h5">
												{{ cartTotals.total }}
												<span
													v-if="typeof cartTotals.exchange !== 'undefined' && cartTotals.exchange.is_needed"
													class="font-weight-normal exchange-text"
													style="display: block; font-size:11px;">
													{{ cartTotals.exchange.total }}*
												</span>
											</td>
										</tr>
										<tr v-if="isPack">
											<td
												colspan="2"
												class="pt-0 pb-1 pb-3 font-weight-bolder text-right text-primary h5">
												{{ translate('total_volume') }}
											</td>
											<td class="pt-0 pb-1 pb-3 text-right text-primary h5">
												{{ cartTotals.total_volume }}
											</td>
										</tr>
									</tbody>
								</table>
								<b-alert
									v-if="!loadingCartInfo && cartInfo.out_of_stock_code_names.length"
									class="mb-0"
									variant="warning"
									show>
									<span
										v-for="(code_name, index) in cartInfo.out_of_stock_code_names"
										:key="index">
										• {{ translate(code_name) }} <br>
									</span>
								</b-alert>
							</div>
							<p
								v-if="thereIsExchange"
								class="exchange-text text-right small mt-2 px-2">
								{{ translate('exchange_disclaimer') }}
							</p>
						</li>
					</div>
					<div class="col-12 align-self-end">
						<li
							class="h-auto border-0 px-0 pt-2 d-flex">
							<div class="form-check mt-2">
								<input
									id="policyNProcedures"
									v-model="disclaimer"
									name="policyNProcedures"
									class="form-check-input"
									type="checkbox"
									value="">
								<label
									class="form-check-label"
									for="policyNProcedures"
									v-html="translate('policies_and_procedures_agreement_register', {policies_and_procedures: `<a target='_blank' href='${TERMS_AND_CONDITIONS_REDIRECTION}'>${ translate('policies_and_procedures') }</a>`})" />
							</div>
						</li>
						<div
							class="btn-group w-100"
							role="group">
							<b-button
								:disabled="!disclaimer"
								variant="primary"
								size="lg"
								block
								class="mt-3"
								@click="goSuccess">
								{{ translate('confirm') }}
							</b-button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { MMMDY_FORMAT } from '@/settings/Dates';
import isLoading from '@/components/Loading';
import { TERMS_AND_CONDITIONS_REDIRECTION, TIMEZONE } from '@/settings/General';
import DocumentTitle from '@/mixins/DocumentTitle';
import { PRODUCTS_LINKS, SIMPLE_PRODUCTS_PARENT_PLACEHOLDER } from '@/settings/Products';
import {
	SHOW_SIGNATURES_BY_COUNTRY as countriesListForSignature,
	SHIPPING_METHOD_CODES as shippingMethods,
} from '@/settings/Shipping';
import { AVAILABLECC as availableCC, CREDIT_CARD_FORM_METHODS } from '@/settings/CreditCard';
import { INTERNAL_SERVER_ERROR, UNPROCESSABLE } from '@/settings/Errors';
import { REQUIRED_NIN_COUNTRIES } from '@/settings/Profile';
import AddressBook from '@/util/AddressBook';
import Cart from '@/util/Cart';
import Country from '@/util/Country';
import WindowSizes from '@/mixins/WindowSizes';
import CommonMix from './mixins/Common';
import PackMix from './mixins/Package';
// import { PRE_CONFIRMATION_NOTE_COUNTRIES } from '@/settings/Country';
import { currency } from '@/config/Filters';
import { affiliate, distributor } from '@/settings/Roles';

export default {
	name: 'BecomeDistributorConfirmation',
	components: { isLoading },
	filters: {
		currency,
	},
	mixins: [CommonMix, DocumentTitle, WindowSizes, PackMix],
	data() {
		return {
			cart: new Cart(),
			cartData: new Cart(),
			productsLinks: PRODUCTS_LINKS,
			alert: new this.$Alert(),
			stateData: new Country(),
			loadShippingAddress: new AddressBook(),
			loadBillingAddress: new AddressBook(),
			isCompany: '',
			disclaimer: '',
			idempotencyId: '',
			idempotencyId2: '',
			form: {
				gender: '',
				birthdate: '',
				national_identification_number: '',
				tax_id: '',
				company: '',
			},
			shipping: {
				first_name: '',
				last_name: '',
				address: '',
				address2: '',
				postcode: '',
				city: '',
				region_id: '',
				country_code: '',
				// signature_required: '',
			},
			billing: {
				first_name: '',
				last_name: '',
				address: '',
				address2: '',
				postcode: '',
				city: '',
				region_id: '',
				country_code: '',
			},
			paymentMethod: {
				name: '',
				card_type: '',
				last_digits: '',
				card_type_2: '',
				last_digits_2: '',
				split_amount: 0,
				wallet_split: false,
				wallet_split_amount: 0,
			},
			shippingMethod: {
				shipping_method: '',
			},
			// productsQty: {},
			MMMDY_FORMAT,
			countriesListForSignature,
			TERMS_AND_CONDITIONS_REDIRECTION,
			timezone: TIMEZONE,
			packageName: '',
			packageOption: 0,
			isPack: 0,
			bindProps: {
				inputOptions: {
					showDialCode: true,
				},
				disabledFetchingCountry: true,
			},
			requiredNinCountries: REQUIRED_NIN_COUNTRIES,
			shippingMethods,
			availableCC,
		};
	},
	computed: {
		canSplitWithWallet() {
			return this.$user.auth() && [distributor, affiliate].includes(this.$user.details().type);
		},
		walletSplit() {
			return !!this.paymentMethod.wallet_split;
		},
		isCreditCard() {
			return CREDIT_CARD_FORM_METHODS.includes(this.paymentMethod.name);
		},
		walletSplitAmount() {
			return Number(this.paymentMethod.wallet_split_amount);
		},
		imageConfirmation() {
			return 'confirmation.jpg';
		},
		loadingCartTotals() {
			try {
				return this.cart.data.loading;
			} catch (error) {
				return true;
			}
		},
		cartTotals() {
			try {
				return this.cart.data.response.data.data.attributes;
			} catch (error) {
				return {};
			}
		},
		smallTextTotals() {
			try {
				return this.cartTotals.small_subtotals;
			} catch (error) {
				return [];
			}
		},
		cartProducts() {
			try {
				return this.cartTotals.products.reduce((accumulator, item) => {
					const parentCodeName = item.parent.code_name || SIMPLE_PRODUCTS_PARENT_PLACEHOLDER;

					accumulator[parentCodeName] = [
						...accumulator[parentCodeName] ?? [],
						item,
					];

					return accumulator;
				}, {});
			} catch (error) {
				return {};
			}
		},
		configurableProducts() {
			return Object.fromEntries(Object.entries(this.cartProducts).filter(([parent]) => parent !== SIMPLE_PRODUCTS_PARENT_PLACEHOLDER));
		},
		simpleProducts() {
			return this.cartProducts[SIMPLE_PRODUCTS_PARENT_PLACEHOLDER];
		},
		hasCartTotals() {
			return !!Object.keys(this.cartTotals);
		},
		thereIsExchange() {
			const theresExchange = this.products.find((item) => item.attributes.exchange);
			return typeof theresExchange !== 'undefined';
		},
		loadingShippingAddress() {
			return !!this.loadShippingAddress.data.loading;
		},
		loadingBillingAddress() {
			return !!this.loadBillingAddress.data.loading;
		},
		cardLogo() {
			return this.getCardLogo(this.paymentMethod.card_type);
		},
		cardLogo2() {
			return this.getCardLogo(this.paymentMethod.card_type_2);
		},
		isSplitPayment() {
			return !!this.paymentMethod.last_digits_2;
		},
		splitAmount1() {
			return this.paymentMethod.split_amount;
		},
		splitAmount2() {
			return this.numericTotal - this.splitAmount1;
		},
		displayedSplitAmount1() {
			return this.formatAmount(this.splitAmount1);
		},
		displayedSplitAmount2() {
			return this.formatAmount(this.splitAmount2);
		},
		shippingMethodInfo() {
			try {
				const shippingMethod = { ...this.shippingMethods[this.shippingMethod.shipping_method] };
				shippingMethod.name = this.shippingMethod.shipping_method;
				return shippingMethod;
			} catch (error) {
				return {};
			}
		},
		deliveryDays() {
			try {
				const deliveryDays = this.shippingMethodInfo.delivery_days;
				return deliveryDays[this.country] ? deliveryDays[this.country] : [];
			} catch (error) {
				return [];
			}
		},
		shippingNotice() {
			try {
				const shippingNotice = this.shippingMethodInfo.shipping_notice;
				return shippingNotice[this.country] ? shippingNotice[this.country] : shippingNotice.default;
			} catch (error) {
				return [];
			}
		},
		cartInfo() {
			try {
				return this.cartData.data.response.data.response;
			} catch (error) {
				return [];
			}
		},
		loadingCartInfo() {
			try {
				return this.cartData.data.loading;
			} catch (error) {
				return false;
			}
		},
		numericTotal() {
			try {
				return this.cartTotals.numeric_total;
			} catch (error) {
				return {};
			}
		},
		numericTotals() {
			try {
				return this.cartTotals.numeric_totals;
			} catch (error) {
				return {};
			}
		},
		numericTotalPurchased() {
			try {
				return (this.numericTotals.subtotal || 0) + (this.numericTotals.discount || 0);
			} catch (error) {
				return 0;
			}
		},
		currentSteps() {
			try {
				return this.steps;
			} catch (error) {
				return 0;
			}
		},
	},
	async created() {
		this.bindProps.defaultCountry = this.country;
		this.bindProps.onlyCountries = [this.country];
		this.idempotencyId = this.createUuid();
		this.idempotencyId2 = this.createUuid();
		// this.loadProductsInfo();
		Object.keys(this.steps).forEach((key) => {
			if (key !== this.$route.name) {
				const previous = this.getStepInformation(key);
				if (key === 'BecomeDistributorPack') {
					if (previous.product.is_pack) {
						this.packageName = previous.product.package_code_name;
						this.packageOption = previous.product.package_option;
						this.isPack = previous.product.is_pack;
					}
				} else if (key === 'BecomeDistributorAccountAndShipping') {
					try {
						// Personal Info
						const { personal_information: personalInformation } = previous;
						if (typeof personalInformation === 'undefined') {
							this.$router.replace({ name: key });
							return null;
						}
						this.isCompany = personalInformation.is_company;
						Object.keys(this.form).forEach((property) => {
							if (personalInformation[property] !== '') {
								this.form[property] = personalInformation[property];
								if (property === 'national_identification_number' || property === 'tax_id') {
									this.form[property] = this.form[property].replace(/.(?=.{4,}$)/g, '•');
								}
							}
						});

						// Shipping
						const { shipping_address: shippingAddress } = previous.shipping;
						if (typeof shippingAddress === 'undefined') {
							this.$router.replace({ name: key });
							return null;
						}
						const shippingMethod = previous.shipping_data;
						Object.keys(shippingMethod).forEach((element) => {
							this.shippingMethod[element] = shippingMethod[element];
						});
						this.setShippingAddress(shippingAddress.address_id);
						this.shipping.country_code = this.shipping.country_code.toLowerCase();
					} catch (error) {
						this.$router.replace({ name: key });
						return null;
					}
				} else if (key === 'BecomeDistributorPaymentMethod') {
					try {
						const { billing: billingAddress } = previous.payment;
						if (typeof billingAddress === 'undefined') {
							this.$router.replace({ name: key });
							return null;
						}
						const paymentMethod = previous.card_data;
						Object.keys(paymentMethod).forEach((element) => {
							this.paymentMethod[element] = paymentMethod[element];
						});
						this.paymentMethod.split_amount = previous.payment.payment_method.split_amount;
						this.paymentMethod.name = previous.payment.payment_method.name;
						this.paymentMethod.wallet_split = previous.payment.payment_method.wallet_split;
						this.paymentMethod.wallet_split_amount = previous.payment.payment_method.wallet_split_amount;
						this.setBillingAddress(billingAddress.address_id);
						this.billing.country_code = this.billing.country_code.toLowerCase();
					} catch (error) {
						this.$router.replace({ name: key });
						return null;
					}
				}
			}
			return null;
		});
		if (this.shoppingCartId !== 'undefined' && this.shoppingCartId !== '') {
			Promise.all([
				this.loadPackageInfo(),
				this.cartData.getCartInfo(this.shoppingCartId),
				this.cart.getCart(this.shoppingCartId, {
					is_register: 1,
					is_pack: this.isPack,
					package_code_name: this.packageName,
					package_option: this.packageOption,
				}, 'become_distributor'),
			]).then(() => {
				this.updateTotalsEstimates()
					.then(() => this.saveSubtotal(this.cartTotals.numeric_totals.subtotal))
					.catch(() => this.alert.error(this.translate('something_went_wrong')));
			});
		}
	},
	methods: {
		setShippingAddress(addressId) {
			this.loadShippingAddress.getAddress(this.$user.details().id, addressId).then((response) => this.setAddressInfo('shipping', response.attributes));
		},
		setBillingAddress(addressId) {
			this.loadBillingAddress.getAddress(this.$user.details().id, addressId).then((response) => this.setAddressInfo('billing', response.attributes));
		},
		setAddressInfo(addressType, address) {
			this[addressType].first_name = address.first_name;
			this[addressType].last_name = address.last_name;
			this[addressType].address = address.address;
			this[addressType].address2 = address.address2;
			this[addressType].city = address.city;
			this[addressType].postcode = address.postcode;
			this[addressType].country_code = address.country.code;
			this[addressType].region_id = address.region.id;
			this.stateData.getState(this[addressType].region_id).then((state) => {
				this[addressType].region_id = state.attributes.name;
			});
		},
		goSuccess() {
			// if (PRE_CONFIRMATION_NOTE_COUNTRIES.includes(this.country)) {
			if (['CR'].includes(this.country)) {
				const alertConfig = {
					icon: 'info',
					title: this.translate('note_title'),
					text: this.translate(`${this.country.toLowerCase()}_note_text`),
					showCancelButton: true,
					confirmButtonText: this.translate('confirm_note_i_agree'),
					reverseButtons: true,
				};
				this.alert.preConfirm(alertConfig, () => {
					this.goSuccessValidated();
				}, () => {});
			} else {
				this.goSuccessValidated();
			}
		},
		goSuccessValidated() {
			let payload = {};
			Object.keys(this.steps).forEach((key) => {
				if (key !== this.$route.name) {
					if (key === 'BecomeDistributorPack') {
						const previous = this.getStepInformation(key);
						payload = { ...payload, ...previous };
					} else if (key === 'BecomeDistributorAccountAndShipping') {
						payload = { ...payload, ...this.getStepInformation(key) };
					} else if (key === 'BecomeDistributorPaymentMethod') {
						payload = { ...payload, ...this.getStepInformation(key) };
						payload.payment.payment_method.idempotency_id = this.idempotencyId;
						payload.payment.payment_method.idempotency_id_2 = this.idempotencyId2;
					}
				}
			});
			const options = {
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
			};
			this.alert.loading(this.translate('processing_request'), this.translate('white_util_checkout'), options);
			const validation = { ...payload, step: this.steps[this.$route.name].validation };
			this.validateStep(validation).then((response) => {
				this.removeRegister().then(() => {
					this.$user.init();

					const {
						redirect_url: redirectUrl,
						verification_required: verificationRequired,
						crypto_wallet: cryptoWallet,
					} = response.response;
					// Redirect to instructed page
					if (redirectUrl) {
						window.location.href = redirectUrl;
						return;
					}
					// Pass payment method to success page in case we need to display an alert for it
					const routeOptions = {
						name: 'BecomeDistributorSuccess',
						query: { payment_method: payload.payment.payment_method.name ?? '' },
					};
					// Show verbal verification alert
					if (verificationRequired) {
						routeOptions.query.verification_required = true;
					}
					// Go to crypto payment details
					if (cryptoWallet) {
						routeOptions.query = { wallet_address: cryptoWallet };
					}
					// Go to new route
					this.$router.replace(routeOptions);
					// Close 'Processing request...' alert
					this.alert.close();
				}).catch(() => {});
			}).catch((error) => {
				this.idempotencyId = this.createUuid();
				this.idempotencyId2 = this.createUuid();
				if (UNPROCESSABLE.includes(error.status)) {
					const {
						cart_id: cartId,
						payex,
						'payment.payment_method.name': paymentMethodName,
					} = error.errors;
					if (typeof cartId !== 'undefined') {
						this.saveCart(cartId);
					}
					if (typeof payex !== 'undefined') {
						let response = '';
						payex.forEach((item) => { response += `${item} <br>`; });
						this.alert.error(this.translate('something_went_wrong'), response, true);
					}
					if (typeof paymentMethodName !== 'undefined') {
						let response = '';
						paymentMethodName.forEach((item) => { response += `${item} \n`; });
						this.alert.toast('error', response, { timer: 6000 });
					}

					// If the validation error wasn't one of the above
					if ([cartId, payex, paymentMethodName].every((field) => typeof field === 'undefined')) {
						this.alert.toast('error', this.translate('something_went_wrong'), { timer: 6000 });
					}
				} else if (INTERNAL_SERVER_ERROR.includes(error.status)) {
					this.alert.toast('error', this.translate('something_went_wrong'), { timer: 2500 });
					setTimeout(() => { this.$router.replace({ name: Object.keys(this.steps)[0] }); }, 2500);
				}
			});
			return null;
		},
		productHasLink(key) {
			return typeof this.productsLinks[key] === 'string' || this.productsLinks[key] instanceof String;
		},
		getCardLogo(cardType) {
			if (cardType.length > 0 && this.availableCC.includes(cardType)) {
				return cardType;
			}
			return 'cc';
		},
		formatAmount(amount) {
			if (amount) {
				return `$${amount.toFixed(2)} USD`;
			}
			return '0 USD';
		},
	},
};
</script>
<style>
	.text-small {
		font-size: 0.75em;
	}
</style>
<style scoped>
.success-icon::before {
	color: #63D13E !important;
}
</style>
